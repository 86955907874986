/**
 *  项目的配置文件
 */

var chatUrlConfig="";
var apiUrlConfig=""
if (location.hostname == 'test.easybuykz.com') { //测试环境
  chatUrlConfig   = 'wss://test-customer.easybuykz.com';  
  apiUrlConfig = 'https://test.easybuykz.com/'
} else if (location.hostname == 'www.easybuykz.com' || location.hostname == 'easybuykz.com') { //预发布环境
  chatUrlConfig   = 'wss://customer.easybuykz.com';  
  apiUrlConfig = 'https://www.easybuykz.com/'
} else if (location.hostname == 'www.dzon.kz' || location.hostname == 'dzon.kz') { //正式环境
  chatUrlConfig   = 'wss://customer.dzon.kz';  
  apiUrlConfig = 'https://www.dzon.kz/'
}else { //开发环境
  chatUrlConfig   = 'https://api-dzon.fnsjfnjf.com';  
  apiUrlConfig = 'https://api-dzon.fnsjfnjf.com/'
}

// 测试 https://test.easybuykz.com/api/
// 正式 https://api.easybuykz.com/api/
// 这里的接口被代理掉了,但是上传图片的时候有用到
export const apiUrl = apiUrlConfig;//接口请求地址

// 测试 wss://test-customer.easybuykz.com
// 正式 wss://customer.easybuykz.com

export const chatUrl = chatUrlConfig;//客服地址

// 测试 https://test-m.easybuykz.com
// 预发布 https://m.easybuykz.com
// 正式 https://m.dzon.kz
export const mUrl = 'https://m.dzon.kz'//移动端网页地址
// export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
// export const curLang = 'ru';
export const curLang = localStorage.getItem('languageSet')||'ru';
// console.log(curLang);

export const title = 'dzonkz';//浏览器顶部title
export const gdKey = '';//高德web-js key
export const gdSecurityCode = ''//高德web-js 安全密钥
export const statShowDebug = false;//是否开启统计的调试

/** copyright *** slodon *** version-v5.1 *** date-2023-07-06 ***主版本v5.1**/

// locale:localStorage.getItem('languageSet')||'zh',
// export const curLang


/*
* 语言文件 ru:哈萨克斯塔
* */
export const lang_ru = {
    '账号安全': 'Безопасность учетной записи',
    '尚未绑定手机号': 'Номер телефона не привязан',
    '修改号码': 'Изменить номер ',
    '绑定号码': 'Привязать номер ',
    '电子邮箱': 'Электронная почта',
    '绑定邮箱:': 'Привязать электронную почту ',
    '若邮箱已停用，请及时更换': 'Если электронная почта была отключен, необходимо заменить его вовремя',
    '尚未绑定邮箱': 'Электронная почта еще не привязана',
    '手机号码': ' Номер телефона',
    '绑定邮箱': 'Привязать электронную почту ',
    '登录密码': ' Пароль ',
    '修改密码': 'Изменить пароль',
    '设置密码': ' Установить пароль',
    '修改邮箱': 'Изменить адрес электронной почты',
    '绑定手机:': 'Привязать мобильный телефон ',
    '若手机丢失或停用，请及时更换': 'Если мобильный телефон утерян или отключен, необходимо заменить его',
    '重置密码': 'Сброс пароля',
    '地址管理': 'Управление адресами',
    '默认地址': 'Адрес по умолчанию',
    '新增地址': 'Добавить адрес',
    '全部地址': 'Полный адрес',
    '设置为默认': 'Установить по умолчанию',
    '编辑地址': 'Изменить адрес',
    '删除地址': 'Удалить адрес',
    '暂无收货地址～': 'Нет адреса доставки~',
    '添加收货地址': 'Добавить адрес доставки',
    '收货人': ' Получатель ',
    '请输入收货人姓名': 'Введите имя грузополучателя',
    '请输入收货人': 'Введите грузополучателя',
    '电话号码': 'Номер телефона',
    '在使用账户余额时需输入支付密码以保证您的资金安全': ' При использовании баланса счета необходимо ввести платежный пароль для обеспечения сохранности ваших средств',
    '所在地区': ' Расположение ',
    '请选择所在地区': 'Выберите регион',
    '详细地址': ' подробный адрес  ',
    '请输入详细地址': 'Введите данные об адресе',
    '请输入正确的地址':'Введите правильный адрес.',
    '设为默认地址': 'Установить как адрес по умолчанию',
    '取 消': ' Отмена ',
    '确 定': 'подтвердить ',
    '我的退货': 'Мои возвращения',
    '我的退款': ' Мой возврат',
    '商品明细': ' Сведения о товаре',
    '申请时间': 'время  заявления',
    '申请类型': ' тип заявления ',
    '状态': ' Состояние ',
    '操作': ' Работать ',
    '下单时间': 'Время заказа',
    '订单号': 'Номер заказа',
    '查看详情': 'Проверить детали',
    '发货': ' отправка товара ',
    '我已知悉？': 'Я уже знаю? ',
    '去注册': 'Перейти к регистрации',
    '添加/编辑': 'Добавить/редактировать',
    '（同订单商品可批量申请）': '(Одни и те же позиции заказа могут применяться партиями)',
    '支付密码': ' Платежный пароль ',
    '退款金额不可修改，最多': ' Сумма возврата не может быть изменена до',
    '含运费': 'Доставка включена ',
    '不含运费': ' Не включает доставку',
    '修改金额': ' Изменить сумму',
    '退款金额可修改，最多': ' Сумма возврата может быть изменена до ',
    '请输入退款金额': 'Введите сумму возврата',
    '取消': ' Отмена',
    '确定': ' подтвердить ',
    '申请件数': 'Количество заявок',
    '请输入申请件数': 'Введите число заявок',
    '货物状态': ' Статус груза ',
    '退款原因': 'Причина возврата',
    '退货原因': ' Причина возвращения',
    '请选择退款原因': 'Выберите причину возврата',
    '请选择退货原因': 'Выберите причину возвращения',
    '问题描述': 'Описание проблемы ',
    '请输入问题描述(选填)': 'Введите описание проблемы (необязательно)',
    '上传凭证': ' Загрузить доказательство',
    '批量售后商品选择': 'Пакетный выбор товара послепродажного обслуживания',
    '首页': 'Главная',
    '文章': ' Статья ',
    '暂无文章数据': ' Нет данных о статье',
    '文章分类': ' Классификация статей',
    '最新文章': 'Последние статьи',
    '我的余额': 'Мой баланс',
    '总金额': 'Общая сумма ',
    '(元):': '(Юань)',
    '可用余额': 'Доступный баланс',
    '充值': 'пополнение счета ',
    '提现': ' снятие ',
    '余额明细': ' Детали баланса',
    '充值明细': ' Детали пополнения',
    '提现明细': ' Детали снятие ',
    '交易日期': 'Дата сделки',
    '收入/支出': ' доходы и расходы ',
    '变动原因': 'Причина изменения',
    '暂无余额明细~': ' Нет сведений о балансе ',
    '充值金额': 'Сумма пополнения',
    '(元)': '(Юань)',
    '暂无充值明细~': 'Нет сведений о пополнении счета~',
    '提现单号': 'Номер снятия наличных',
    '提现金额': ' Сумма снятия ',
    '手续费': ' комиссионный сбор ',
    '失败原因': 'Причина отказа',
    '₸': '₸',
    '查看': ' Проверять ',
    '暂无提现明细~': ' Нет подробностей о снятии средств~',
    '您尚未设置支付密码，请设置支付密码后再进行后续操作。': 'Платежный пароль еще не установлен',
    '去设置': 'Перейдите в настройки',
    '输入支付密码': 'Введите платежный пароль',
    '请输入支付密码以完成后续操作': 'Для продолжения введите платежный пароль',
    '请输入平台支付密码': 'Введите платежный пароль платформы',
    '确认': 'Подтверждать ',
    '绑定手机号': ' Привязать номер телефона',
    '请输入验证码': 'проверочный код',
    's后获取': 'Получить позже',
    '获取验证码': 'Получить код',
	'后重新获取': ' Получить позже',
    '绑定': ' Связывать ',
    '该手机号已被绑定，请更换其他手机号': 'Используемый номер мобильного телефона, смена на другой номер',
    '继续绑定:将解除与账号': '     ‘Продолжить привязку: учетная запись будет отвязана',
    '的绑定关系': ' Обязательные отношения',
    '更新信息:授权信息将绑定到账号': 'Обновить информацию: информация авторизации будет привязана к учетной записи',
    '继续绑定': ' Продолжить привязку',
    '更新信息': 'Обновить информацию',
    '品牌中心': ' Бренд-центр',
    '全选': 'Выбрать все',
    '商品信息': 'Информация о товара',
    '单价': 'Цена за единицу ',
    '数量': 'Количества',
    '小计（元）': 'итог (юань)',
    '联系客服': 'первая помощь',
    '满减': 'за полный минус',
    '促销': ' промоакция ',
    '库存': 'В наличии',
    '库存不足': 'нет в наличии ',
    '剩余': ' Оставшийся ',
    '件': ' штука ',
    '移入收藏夹': 'Переместить в избранное',
    '确定删除选中商品？': 'Вы уверены, что хотите удалить выбранный товара? ',
    '删除': ' Удалить ',
    '失效商品': 'товары с истекшим сроком действия ',
    '清空': 'Очистить',
    '失效': ' срок действия истек ',
    '删除选中的商品': 'Удалить выбранные',
    '请输入手机号': 'Введите  номер телефона',
    '已选商品': ' Выбранные товары ',
    '合计': 'Общий ',
    '去结算': 'Перейти к оплате ',
    '这里空空如也，快去首页逛逛吧~': 'Здесь пусто, давайте зайдем на главную и выбираем ~',
    '去首页 > >': ' зайдем на главную ',
    '确定清空失效商品？': 'Вы уверены, что хотите удалить товары с истекшим сроком действия?',
    '购物车中还没有商品，赶紧选购吧': 'В корзине нет товара, спешите выбирать',
    '最新加入的商品': ' Последние добавленные товары ',
    '共': 'Общий',
    '件商品': ' Предметы ',
    '去购物车': 'зайдем  в корзину’',
    '暂无数据': 'Нет данных ',
    '发送链接 >': 'Отправить ссылку >',
    '已读': 'Прочитал ',
    '未读': 'Не прочитано',
    '订单号:': 'Номер заказа ',
    '常见问题': 'часто задаваемые вопросы ',
    '暂未有常见问题~': 'Часто задаваемых вопросов нет ',
    '发送': ' Отправлять ',
    '我的订单': 'Мой заказ',
    '我的足迹': 'Мои шаги ',
    '店铺推荐': 'Рекомендуем',
    '发送链接': 'Отправить ссылку',
    '展开全部': 'Расширить все',
    '收起全部': 'Свернуть все',
    '暂无订单～': 'Нет заказа ',
    '暂无足迹～': 'Нет следов~',
    '店铺名称:': 'Название магазина ',
    '店铺星级:': 'Звездный рейтинг магазина',
    '店铺星级': 'Звездный рейтинг магазина',
    '联系电话:': 'Контактный номер ',
    '店铺评分': 'Рейтинг магазина',
    '用户评价:': 'Пользовательская оценка',
    '高': 'Высокий',
    '低': 'Низкий',
    ' 物流服务:': 'Логистические услуги:',
    '中': ' Серединий ',
    '售后服务:': 'Послепродажное обслуживание:',
    '暂无店铺商品～': 'В магазине нет товаров~',
    '搜索最近联系人': 'Поиск в последних контактах',
    '搜索': ' Поиск ',
    '确定关闭该对话吗？': 'Вы уверены, что хотите закрыть этот диалог? ',
    '暂无数据～': 'Нет данных~',
    '收藏的商品': 'Избранное ',
    '收藏的店铺': 'Любимый магазин',
    '取消全选': 'Убрать выделение со всего',
    '收货地址': 'Адрес доставки',
    '管理': ' Управлять',
    '退款金额': 'Сумма возврата',
    '自营': 'самоуправление',
    '进入店铺': 'Войти в магазин',
    '加入购物车': 'в корзину',
    '特别关注': ' Особое ',
    '普通关注': ' Общая ',
    '暂无收藏店铺': ' Нет любимого магазина',
    '设为特殊关注': 'Установить как особый',
    '取消特殊关注': 'Отменить особое внимание',
    '取消关注': ' Отписаться ',
    '热销推荐': ' Горячая рекомендация',
    '本月上新': 'Новинка этого месяца',
    '购物车': 'Корзина',
    '确认订单': 'Подтвердите заказ',
    '付款': ' Оплата',
    '支付成功': 'Оплата прошла успешно',
    '填写并核对订单信息': ' Заполните и проверьте информацию о заказе',
    '收货信息': 'Информация о доставке',
    '新增收货地址': 'Новый адрес доставки',
    '收起地址': ' Свернуть адрес',
    '暂无收货地址': 'Адрес доставки пока не указан',
    '预留信息': 'Зарезервированная информация ',
    '请输入': ' Пожалуйста вводите',
    '商品清单': 'Список товаров',
    '返回修改购物车': ' Вернуться, чтобы изменить корзину ',
    '店铺宝贝': ' Магазин товаров ',
    '小计': 'итог',
    '店铺:': 'Магазин',
    '虚拟': ' виртуальный',
    '₸': '₸',
    'x': ' x ',
    '赠品': 'Подарок',
    '赠完为止': ' Пока есть запасы ',
    '给卖家留言:': 'Оставить сообщение для продавца:',
    '选填，给卖家留言备注': 'Необязательно, оставьте сообщение продавцу',
    '使用优惠券': 'Использовать купон',
    '暂无可用优惠券': 'Купонов пока нет',
    '成交量': ' Объем ',
    '不使用优惠券': ' Не используйте купоны',
    '折': 'Скидка',
    '优惠': 'Скидка',
    '元': 'юаней',
    '商品金额': 'Сумма товара',
    '店铺总优惠': 'Общие скидки магазина’',
    '运费': 'доставка',
    '免运费': 'Бесплатная доставка',
    '发票': ' Счет',
    '我要开发票': 'Я хочу выставить счет',
    '重新选择': 'Повторно выбрать',
    '使用优惠券/积分抵用': 'Использовать обмен купонов/баллов',
    '平台优惠券': 'Купоны платформы',
    '积分抵扣': ' Вычет Купоны',
    '固定金额券': 'Купон с фиксированной суммой',
    '折扣券': 'Купон на скидку',
    '随机金额券': 'Купон на случайную сумму',
    '使用规则:': 'Использование правил:',
    '可用积分': 'Доступные Купоны',
    '积分抵现': 'Купоны за наличные',
    '订单金额': 'Сумма заказа',
    '取消收藏': 'Неизбранное',
    '可用': 'Доступный',
    '当前积分': 'Текущие Купоны',
    '金额抵用': 'Купоны за сумму',
    '张，优惠': 'Штук,скидки',
    '使用积分': 'Использовать Купоны ',
    '分，抵扣': 'Баллы, вычет',
    '商品总额(含运费)': 'Общая сумма товаров (включая доставку)',
    '共优惠': ' Общая скидка',
    '应付金额': ' Суммы к оплате',
    '送货至': ' Отправить к',
    '收货人:': 'Получатель:',
    '提交订单': 'Подтвердить заказ ',
    '发票历史信息选择': 'Выбор информации об истории счетов',
    '默认': 'По умолчанию',
    '促销减': 'Скидка',
    '暂不开发票': 'Еще не выставлен счет',
    '发票内容': 'Содержание счета',
    '商品类别': 'Категория товаров',
    '发票抬头': 'Счет',
    '公司': ' Компания ',
    '请在此填写发票抬头': 'Заполните заголовок счета',
    '发票类型': 'Тип счета',
    '普通发票': 'Обычный счет-фактура',
    '增值税专用发票': 'Счет-фактура по специальному налогу на добавленную стоимость',
    '单位名称': 'Название компании',
    '请在此填写单位名称': 'Введите название подразделения',
    '税号': 'Налоговый номер',
    '请在此填写纳税人识别号': 'Заполните идентификационный номер налогоплательщика',
    '注册地址:': 'Зарегестрированный адрес ',
    '请输入注册地址': 'Введите адрес регистрации',
    '注册电话:': 'Регистрационный телефон:',
    '请输入注册电话': 'Введите зарегистрированный номер телефона',
    '开户银行:': 'Банк счетов:',
    '请输入开户银行': 'Введите банк счета',
    '银行账户:': ' банковский счет ',
    '请输入银行账户': 'Введите банковский счет',
    '收票人姓名': ' имя получателя ',
    '请输入收票人姓名': 'Введите имя получателя',
    '收票人电话:': ' Номер телефона получателя:',
    '请输入收票人电话': 'Введите номер телефона получателя',
    '收票人地址:': 'Адрес получателя',
    '请输入收票人地址': 'Введите адрес получателя',
    '收票邮箱': 'Почтовый адрес получателя',
    '请输入收票邮箱': 'Введите адрес электронной почты получателя',
    '设为默认发票': 'Установить как счет-фактуру по умолчанию',
    '返回': 'Возвращаться',
    '移除无货商品': 'Удалить товары, которых нет в наличии',
    '继续下单': 'Продолжить оформление заказа ',
    '联系方式': 'Контактная',
    '新增发票': 'Добавить счет',
    '积分': 'Купон',
    '抵扣': 'Вычет ',
    '使用': 'Использовать ',
    '暂不使用积分': 'Временно не использовать баллы’',
    '使用规则': 'Правила использования',
    '订单大于等于': 'заказ больше или равен ',
    '积分支付不超过订单金额的': 'Оплата баллов не превышает сумму заказа',
    '积分使用数量为': ' Количество использованных баллов',
    '的整数倍': 'целые кратные',
    '积分等于': 'баллов равенства',
    '联系方式:': 'Контактная',
    '收货地址:': 'Адрес доставки:',
    '更换地址': 'изменить адрес ',
    '新建地址': 'Новый адрес',
    '订单备注': 'Примечания к заказу',
    '给商家留言': 'Оставить сообщение продавцу',
    '积分选择': ' Выбор баллов ',
    '暂无可选积分': 'Пока нет доступных баллов',
    '商品金额:': 'сумма товара:',
    '实付款:': 'Фактический платеж:',
    '积分 +': 'баллов',
    '当前积分数:': 'Текущие баллов',
    '积分不足': 'Недостаточно баллов',
    '发票历史信息选择:': 'Выбор информации об истории счетов:',
    '发票内容:': 'Содержание счета:',
    '发票抬头:': 'Счет:',
    '个人': 'Личный',
    '发票类型:': 'Тип счета:',
    '单位名称:': 'Название компании:',
    '提交修改': 'Отправить изменения',
    '注册地址': 'Зарегестрированный адрес',
    '注册电话':'Регистрационный телефон',
    '开户银行': 'Банк',
    '银行账户': 'Банковский счет',
    '收票人电话': 'Телефон получателя',
    '收票人地址': 'Адрес получателя',
    '收票邮箱:': 'Электронный адрес получателя',
    '我的优惠卷': 'Мой купон',
    '未使用': 'Неиспользованный',
    '已使用': 'Использовал',
    '已过期': 'Истекший',
    '立即使用': 'Использовать немедленно',
    '精选': 'Выбранный',
    '全部': 'Все',
    '随机券': 'Случайный купон',
    '领取': 'Получать',
    '已领取': 'Получен',
    '已抢完': 'Распроданный  все',
    '已抢': ' Распроданный',
    '已抢光': 'Уже ничего нет', 
    '立即领取': 'Получать',
    '个人中心': ' Персональный центр ',
    '订单详情': 'Информация для заказа',
    '开始付尾款': ' Начать оплату за остальные',
    '确认收货': 'Подтвердить получение товара',
    '立即支付': 'Платить немедленно',
    '评价': 'Отзывы',
    '取消订单': 'Отменить заказ',
    '修改地址': 'Сменить адрес',
    '删除订单': 'Удалить заказ',
    '支付订单': 'Оплатить заказа',
    '商家发货': 'отправить заказа',
    '交易关闭': 'закрыть заказа',
    '送货方式': 'Способы доставки',
    '无需物流': 'Без доставки',
    '快递': ' Экспресс-доставка',
    '联系电话': 'Контактный номер',
    '承运人': 'Перевозчик',
    '联系人': 'Контакт',
    '运单号': 'Номер накладной',
    '暂无物流信息': 'Нет информации о доставки',
    '订单信息': 'Информация о заказа ',
    '卖家': ' Продавец ',
    '收货人信息':'Информация о получателе',
    '交易流水号': 'Серийный номер транзакции',
    '订单取消备注': 'Примечание об отмене заказа',
    '商品详情': 'О товаре',
    '商品总额': 'общая сумма товаров',
    '满优惠': 'купон',
    '-₸': '-₸',
    '运费金额': 'Сумма доставки',
    '店铺优惠券': 'Магазин купонов',
    '实际金额': 'Фактическая сумма',
    '取消订单理由': 'Причина отмены заказа',
    '积分订单详情': 'Детали заказа баллов',
    '商品合计': ' Всего по товаров ',
    '已关注': ' подписана',
    '关注店铺': 'магазином подписана',
    '分享': ' Делиться ',
    'QQ': 'QQ',
    '新浪': 'Sina',
    '微信': 'Wechat',
    '分享到微信': 'Поделиться в wechat',
    '已兑换': 'обмен',
    '价格': 'Цена',
    '原价': 'Изначальная цена',
    '商品已下架，欢迎挑选其他商品~': 'Товары сошли с полки, добро пожаловать выбрать другие товары',
    '商品已下架': 'Товар снят с полок, вы можете выбрать другие товары~',
    '热门礼品': ' Горячий подарок',
    '人气礼品': ' актуальный подарок ',
    '+₸': '+₸',
    '手机下单': 'Мобильный заказ',
    '该商品暂无详情~': 'Данный товар не имеет подробностей~',
    '综合评分': 'Общая оценка',
    '描述相符':'Описание соответствует',
    '服务态度': 'сервис',
    '发货速度': 'Скорость доставки',
    '服务承诺': 'Приносить присягу ',
    '正品保障': 'обеспечения оригинальность',
    '客服电话': 'первая помощь телефоны',
    '店铺首页': 'главная страница магазинов  ',
    '请输入关键词': 'Введите ключевые слова',
    '搜本店': ' Поиск в нашем магазине',
    '我的购物车': 'Корзина',
    '本店全部分类': 'Все категории в этом магазине',
    '所有商品': 'Все товары',
    '请输入...': 'Пожалуйста входите...',
    '邮箱管理': 'Управление электронной почты ',
    '修改电子邮箱': 'Изменить адрес электронной почты',
    '绑定电子邮箱': 'Привязать адрес электронной почты',
    '请输入邮箱': 'Введите адрес электронной почты',
    '当前邮箱': ' Текущая электронная почта ',
    '请输入邮箱验证码': 'Введите код проверки электронной почты',
    '请输入新邮箱': 'Введите новый адрес электронной почты',
    '下一步': 'Следующий шаг',
    '温馨提示': 'Добрые советы',
    '为了保障您的账号安全，变更重要信息需进行身份验证。': 'Для изменения важной информации требуется аутентификация',
    '变更过程中有任何疑问请联系在线客服解决。': 'Обратитесь в онлайновую службу поддержки клиентов для решения проблемы',
    '如手机号/邮箱已不再使用无法获取验证码，请联系在线客服解决。': 'Номер мобильного телефона/электронной почты больше не используется, обратитесь в службу поддержки клиентов',
    '评价订单': ' Оценить заказ',
    '订单:': 'Заказ:',
    '综合': 'общий',
    '商品': ' Товар ',
    '服务': 'обслуживание',
    '物流': 'Логистика ',
    '': '',
    '商品评分': ' Рейтинг товаров ',
    '评价晒单': ' Оценочный список',
    '请输入内容': 'Введите содержание',
    '晒图': 'публиковать фото',
    '发表评价': 'публиковать  отзыв',
    '交易评价/晒单': 'Оценка сделки /публиковать фото',
    '待评订单': 'В ожидании оценки ',
    '发评价，得积分，积少成多换商品': 'Отправляйте комментарии, получайте баллы, накапливайте меньше и получайте больше продуктов',
    '评价小贴士': 'Советы по оценке',
    '发表评价可获得会员积分': ' Опубликуйте комментарий, чтобы получить баллы участника',
    '金额': 'сумма',
    '支付金额': 'К оплате',
    '版权所有': 'Все права защищены',
    '提供技术支持': 'обеспечить техническую поддержку ',
    '收藏': 'Избранное',
    '已收藏': 'избранное',
    '暂无足迹': ' Пока нет следов',
    '想起密码？': 'Помнишь пароль?',
    '去登录': 'Логин',
    '找回密码': 'Сброс пароля',
    '请输入6～20位英文、数字、符号': 'Введите от 6 до 20 английских буквы, цифр и символов',
    '商品服务': 'Сервис',
    '店铺热销': 'Горящий',
    '定金预售': 'Предпродажа депозита',
    '全款预售': ' полная предпродажа',
    '距离开始剩余:': 'Расстояние от старта осталось:',
    '距离结束剩余:': 'Расстояние до конца оставшееся:',
    '天': 'день',
    '预售定金₸': ' Предпродажный депозит ₸',
    '可抵₸': ' Можно обменять на ₸',
    '拼团': ' совместный закупок',
    '人团': 'Группа людей',
    ' 团长优惠价₸': 'Цена со скидкой на голову₸',
    '秒杀': 'флэш-распродажа',
    '取消提醒': 'Отменить напоминание',
    '提醒我': 'Напомни мне',
    '阶梯团': 'Лестничная группа',
    '阶梯价': 'Лестница цены',
    '拼团价': 'Групповая цена',
    '预售价': 'Предпродажная цена',
    '秒杀价': 'суперцена',
    '预售价 ₸': 'Предпродажная цена ₸',
    '原价 ₸': ' Первоначальная цена ₸',
    '拼团价 ₸': 'Групповая цена ₸',
    '阶梯价 ₸': 'Ступенчатая цена ₸',
    '秒杀价 ₸': 'Цена флэш-распродажи ₸',
    '销量': 'Продажи',
    '优惠券': 'Купон ',
    '领取优惠券': 'Получить купон',
    '(付尾款时间:': '(время платежа:',
    ' (发货时间:': '(Время отгрузки:',
    '配送至': 'Доставить До',
    '请选择地址': 'Укажите адрес доставки',
    '税号:': 'Идентификатор налогоплательщика:',
    '其他地址': ' Другой адрес',
    '扫码参加预售': ' Сканируйте код для участия в предпродаже ',
    '扫码开团': ' Отсканируйте QR-код, чтобы создать группу ',
    '原价购买': ' Покупка по первоначальной цене',
    '满': 'за полный',
    '立即购买': 'Купить сейчас',
    '看了又看': 'Смотрел и смотрел',
    '店铺等级': 'Уровень магазина',
    '商品评价': 'Отзывы',
    '物流评价': 'Оценка логистики’',
    '售后服务': 'Послепродажное обслуживание',
    '热门收藏': 'Горячие фавориты',
    '已售': 'Продано',
    '品牌': 'Бренд',
    '查看全部': 'Посмотреть все',
    '评分': ' Оценка ',
    '好评率': 'Ставка похвалы',
    '有图': 'Есть картинки',
    '好评': 'Хвалить',
    '中评': 'Средний',
    '差评': 'Плохой отзыв',
    '商家回复:': ' ответ продавца:',
    '该商品暂无评论~': 'Отзывов пока нет~',
    '暂无商品服务~': ' Нет услуги по Товаров ',
    '暂无相关商品~': ' Нет  товаров ',
    '全部分类': 'Все категории',
    '搜索结果': 'Результаты поиска',
    '评论数': 'Количество комментариев',
    '商品类型': 'Типы товаров',
    '仅平台自营': 'Единственная самоуправляемая платформа',
    '件相关商品': 'Похожие Товары',
    '人气': 'Популярность',
    '分类': 'Классификация',
    '筛选结果': 'Отфильтровать результаты',
    '包含分类': 'Содержит категории',
    '暂无分类': 'Пока нет классификации',
    '热门推荐': 'Горячие выборы',
    '收起': 'свернуть',
    '更多选项': 'Больше вариантов',
    '查看更多': 'Узнать больше',
    '亲，抱歉! 您查看的页面失联啦...': 'Потеря содержимого страницы',
    '您可以:去其他地方逛逛': 'Посмотрите на других',
    '商城首页': 'Главная',
    '已收藏的商品': 'Избранное',
    '我们正在努力装修中，敬请期待～': ' Мы усердно работаем над ремонтом, пожалуйста, ждите с нетерпением~',
    '会员收货地址': 'Адрес доставки',
    '超级会员': 'Супер член',
    '立即开通': 'Активировать сейчас',
    '请选择': 'Пожалуйста, выбери',
    '超级会员已到期': 'Срок действия суперчленства истек',
    '立即续费': 'продлевать сейчас ',
    '我的财产': 'Моя собственность',
    '余额': 'Баланс',
    '领券': 'получать Купон',
    '店铺关注': 'подписку',
    '待支付': 'Ожидает оплату',
    '待收货': 'Ожидает доставки',
    '待评价': 'Ожидает оценку',
    '售后/退货': 'После продажи/возврата',
    '全部订单': 'Все заказы',
    '这里空空如也，快去挑选合适的商品吧~': 'В корзине пока нет товаров',
    '暂无商品~': 'Нет товара~',
    '暂无关注店铺~': 'Никакого магазина нет~',
    '我的收藏': 'Избранное',
    '暂无收藏商品~': 'Коллекция пуста',
    '暂无足迹~': 'Нет следов~',
    '还没注册？': 'Еще не зарегистрировались?',
    '账号登录': 'Логин аккаунта',
    '手机登录': 'Войти с телефона',
    '请输入账号/手机号': 'Ввести номер счета/телефоны',
    '请输入密码': 'Ввести пароль',
    '登录': 'Войти',
    '二维码已失效': 'QR-код недействителен',
    '点击刷新': 'Нажмите, чтобы обновить',
    '登录成功': 'Авторизация успешна',
    '打开手机商城，扫描二维码': 'Сканирование QR-кода',
    '免输入': 'Без ввода',
    '反应快': 'Быстрая реакция',
    '更安全': ' безопаснее ',
    '立即注册': 'регистрация',
    '忘记密码': 'Забыл пароль',
    '+': '+',
    '店铺不存在或已关闭': 'Магазин не существует или закрыт',
    '会员信息': 'Информация об участнике',
    '用户头像': 'аватар',
    '仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。': 'Поддерживает только форматы JPG, GIF, PNG, JPEG, BMP; размер файла не должен превышать 4,0 МБ.',
    '会员名:': 'Имя участника:',
	'会员名：': 'Имя участника：',
    '真实姓名': 'Фактическое имя',
    '性别:': 'Пол:',
	'性别：': 'Пол：',
    '保密': ' Конфиденциально ',
    '男': ' Мужской ',
    '女': ' Женский ',
    '昵称:': 'никнейм ',
	'昵称：': 'никнейм ：',
    '生日:': 'День рождения:',
	'生日：': 'День рождения：',
    '年': 'Год',
    '月': 'месяц',
    '日': 'День',
    '保存': 'Сохранять ',
    '待发货': 'Быть доставленным',
	'部分发货': 'Частичная отгрузка',
    '已完成': 'Завершенный ',
    '订单号/商品名称/店铺名称': 'Номер заказа/название товаров/название магазина',
    '查看物流': 'Проверить логистику',
    '物流信息': 'Логистическая информация',
    '积分订单': ' Точечный заказ',
    '待付款': ' Ожидание платежа',
    '商品名称/订单编号': 'Название  товаров /номер заказа',
    '扫码参团': 'Сканируйте код, чтобы присоединиться к группе',
    '兑换时间:': ' Время обмена:',
    '兑换单号: ': 'Номер заявки на обмен:',
    '标记已读': 'Отметить прочитанным',
    '确定删除这条消息吗？': 'Определить удаление?',
    '查看详情 >': 'Подробнее >',
    '暂时没有消息！': 'Пока никаких новостей!',
    '更多': 'Более ',
    '向上滑动浏览更多数据': 'Сдвиньте вверх, чтобы увидеть больше',
    '数据加载中...': 'Загрузка...',
    '数据加载完毕~': ' Загрузка данных завершена~',
    '订单状态': 'Статус заказа',
    '常用地址': 'текуший адрес',
    '查看权益': 'Посмотреть преимущества',
    '修改': ' Исправлять ',
    '请输入短信验证码': 'Введите проверочный код',
    '请再次输入密码': 'Повторный ввод пароля',
    '请输入原密码': 'Введите исходный пароль',
    '请输入新密码': 'Введите новый пароль',
    '请再次输入新密码': 'Повторно введите новый пароль',
    '复杂的密码可使账号更安全且建议定期更换密码。': 'Используйте сложные пароли и регулярно меняйте их',
    '用户发货': 'Доставка пользователя',
    '商品名称': 'Наименование товара ',
    '购买数量': 'Количество покупок',
    '服务类型': 'Тип Обслуживания',
    '物流公司': 'Логистическая компания',
    '物流单号': 'трекинг-номер',
    '请输入物流单号': 'Введите трекинг-номер',
    '退货地址:': 'Обратный адрес:',
    '提交': ' Представлять ',
    '账户管理': 'Управление аккаунтом',
    '手机号管理': ' Управление номером мобильного телефона',
    '消息': 'уведомления',
    '收入': ' Доход ',
    '支出': ' Расход ',
    '日期': ' Дата ',
    '详细说明': 'Подробное описание',
    '产品分类': 'Категории продукта',
    '您好，欢迎来到': 'Здравствуйте, добро пожаловать',
    '退出': 'выход',
    '注册': 'регистрировать',
    '待支付订单': 'Заказы в ожидании оплаты ',
    '待收货订单': 'ожидание поступления',
    '待评价订单': 'подлежащий оценке',
    '商品收藏': ' избранное товаров ',
    '店铺收藏': ' избранное магазинов ',
    '我的账户': ' Мой аккаунт ',
    '我的优惠券': 'купон',
    '官方客服': 'Первая помощь',
    '服务中心': 'Сервисный центр',
    '提现详情': 'Детали вывода средств',
    '申请单号:': 'Номер заявки:',
    '状态:': 'Состояние:',
    '提现金额:': ' Сумма вывода:',
    '手续费:': ' Коммисия',
    '支付宝账号:': ' Аккаунт Alipay:',
    '真实姓名:': 'настоящие фамилия и имя:',
    '申请时间:': 'время (подачи) заявления:',
    '完成时间:': 'время завершения',
    '失败原因:': ' Причина отказа:',
    '余额提现': ' Вывод баланса',
    '温馨提示:提现手续费为': ' Напоминание: комиссия за снятие средств',
    '最低提现金额为₸': 'Минимальная сумма вывода ₸',
    '提现方式:': ' Способ вывода:',
    '请输入提现金额': 'Введите сумму вывода',
    '本次提现手续费': ' комиссия за снятие средств’',
    '剩余可提现金额不足': 'Недостаточно оставшейся обналичиваемой суммы',
    '剩余可提现金额:': 'Остаток суммы снятия наличных:',
    '请输入支付宝账号': 'Введите свой счет Alipay',
    '请输入真实姓名': 'Введите свое настоящее имя',
    '支付密码:': 'Платежный пароль:',
    '请输入支付密码': 'Введите платежный пароль',
    '申请提现': 'Подать заявку на вывод',
    '订单提交成功，请您尽快付款！ 订单号:': 'Заказ успешно отправлен, пожалуйста, оплатите как можно скорее! Номер заказа:',
    '请您在提交订单后': 'Пожалуйста, отправьте свой заказ’',
    '24小时内': 'В течение 24 часов',
    '完成支付，否则订单会自动取消': ' Завершите оплату, иначе заказ будет автоматически отменен',
    '收起详情': ' Свернуть детали',
    '展开详情': ' Расширить детали ',
    '商品名称:': 'Наименование товара:',
    '使用余额支付': 'Оплатить балансом',
    '（可用余额:': '(Доступные средства:',
    '元，目前需要在线支付:': 'Юань, в настоящее время требуется онлайн-платеж:',
    '元）余额不足？': 'Юань) Недостаточный баланс? ',
    '马上充值': 'Пополнить сейчас',
    '未设置支付密码，马上去设置': 'Платежный пароль не был установлен, перейдите, чтобы установить его сейчас',
    '忘记密码?': 'Забыли пароль?',
    '当前手机号 ': 'Текущий номер телефона',
    '微信支付': ' Wechat Pay',
    '扫码完成支付': 'Сканируйте код для завершения платежа',
    '刷新': 'обновить',
    '暂无可用的支付方式，平台正在紧急处理中～': 'Нет доступных способов оплаты',
    'APP': 'ПРИЛОЖЕНИЕ',
    '请先绑定手机号，再进行支付密码操作!': 'Привяжите номер своего мобильного телефона, затем введите платежный пароль!',
    '当前手机号': 'Текущий номер телефона',
    '设置支付密码': 'Установить платежный пароль',
    '请输入原支付密码': 'Введите исходный платежный пароль',
    '请再次输入新支付密码': 'Повторно введите новый платежный пароль',
    '修改支付密码': 'Изменить платежный пароль',
    '请输入新手机号': 'Введите новый номер мобильного телефона',
    '账户充值': ' Пополнение счета',
    '1.填写充值金额': '1. Введите сумму пополнения',
    '2.在线支付': 'Онлайн оплата',
    '3.充值完成': 'Завершил пополнения',
    '填写充值金额': 'Введите сумму пополнения',
    '充值账户': 'Пополнить счет',
    '2.充值金额输入值必须是不小于1且不大于5000；': 'Вводимое значение суммы пополнения должно быть не менее 1 и не более 5000;',
    '3.充值完成后，您可至会员中心查看充值记录。': 'Центр участника Просмотр записи о пополнении счета.',
    '请使用手机微信扫描下方二维码进行支付': 'Сканирование QR-кода для оплаты',
    '您正在充值余额，请尽快支付': 'Вы пополняете свой баланс, пожалуйста, оплатите как можно скоре',
    '选择充值方式': 'Выберите способ пополнения',
    '立即充值': 'пополнить сейчас',
    '如二维码过期，': 'Если срок действия QR-кода истек',
    '重新获取二维码。': 'Повторно получить QR-код.',
    '使用微信扫码支付': 'Используйте скан-код wechat для оплаты',
    '暂未开启充值功能': 'Функция перезарядки еще не открыта',
    '退款单号': 'Номер заказа на возврат',
    '退款退货单号': 'Номер заказа на возврат и возврат товара',
    '重新申请': 'Подать заявку повторно',
    '提交申请': 'Подать заявку',
    '审核中': 'На рассмотрении',
    '完成': 'завершено',
    '退款凭证': 'подтверждение на возврат ',
    '平台审核备注': 'Примечания к обзору платформы ',
    '时间': 'Время',
    '已有账号？': 'У вас уже есть аккаунт? ',
    '注册账号': 'регистрация пользователя',
    '请输入图形验证码': 'Введите графический код проверки',
    '我同意': 'Я согласен',
    '《用户注册协议》': 'Соглашение о регистрации пользователя',
    '《隐私政策》': 'Политика конфиденциальности',
    '已有账号，去登录': 'У вас уже есть учетная запись, войдите в систему',
    '重置支付密码': '‘Сбросить платежный пароль’',
    '请先绑定手机号，再进行重置支付密码操作!': 'Сброс платежного пароля после привязки номера мобильного телефона!',
    '请再次输入支付密码': 'Повторно введите платежный пароль',
    '接收设置': 'Получить настройки',
    '接收': 'Перенимать',
    '不接收': 'Не принимай',
    '暂时没有数据～': 'Временно нет данных~',
    '人购买': 'Люди покупают',
    '立即抢购': 'Купить сейчас',
    '此处添加【403*高度不限】图片': 'Добавить [403*высота без ограничений] сюда',
    '请先绑定手机号，再进行登陆密码操作!': 'Привяжите номер своего мобильного телефона, затем введите пароль для входа в систему!',
    '添加内容': 'Добавить контент',
    '图片标题': ' Название изображения',
    '此处添加【186*340】图片': 'Добавьте сюда изображения [186*340]',
    '图片子标题': 'Подзаголовок изображения',
    '此处添加【172*106】图片': 'Добавьте сюда изображения [172*106]',
    '此处添加【187*120】图片': ' Добавьте сюда изображения [187*120]',
    '个商品': 'Предметы',
    '关注': 'подписаться',
    '人已收藏': 'Люди добавили в избранное',
    '店铺已售': ' Магазин продан’',
    '本店暂无商品~': 'В настоящее время в этом магазине нет товаров~',
    '请输入店铺名称': 'Введите имя магазина',
    '确定删除该地址?': 'Вы уверены, что хотите удалить этот адрес?',
    '提示': 'Намекать',
    '仅退款': 'Только возврат',
    '退货退款': 'Возврат',
    '未收到货': 'не получил товар',
    '已收到货': 'получил товар’',
    '退款退货': 'Возврат/возвращать товары',
    '选择其它支付方式': 'Выберите другой способ оплаты',
    '退款金额不可为0或者负值！': 'Сумма возврата не может быть 0 или отрицательной! ',
    '不可超过最大退款金额！': 'Максимальная сумма возврата не может быть превышена! ',
    '最多上传5张！': 'Загрузить не более 5 фотографий! ',
    '提现功能已关闭': 'Функция вывода закрыта',
    '密码输入错误，请重新输入': 'Неверный пароль, введите заново',
    '最低限购1件!': 'Минимальный лимит покупки - 1 штука!',
    '数量超出购买范围！': 'Количество превышает диапазон покупки! ',
    '超过购买限制!': 'Превышен лимит покупки!',
    '移入收藏夹成功！': 'Успешно перенесено в избранное! ',
    '删除成功！': 'Успешно удалено! ',
    '清空失效商品成功！': 'Удаление недопустимых продуктов успешно! ',
    '结算商品不能为空！': 'Продукт расчетов не может быть пустым!',
    '在线': 'В сети',
    '请选择图片类型文件': 'Выбор типа файла изображения',
    '是否要发送剪切板的图片?': 'Вы хотите послать фотографию буфера обмена?',
    '账号已登出': 'Учетная запись вышла из системы',
    'warning': 'Предупреждение',
    '离线': 'Не в сети',
    '[图片]': '[картина]',
    '[商品]': '[товар]',
    '[订单]': '[Заказы]',
    '确定取消收藏吗?': '‘Вы уверены, что хотите отменить сбор?',
    '请选择商品': 'Выберите товары',
    '邮箱格式不正确，请重新输入！': 'Формат почтового ящика неверен, введите заново! ',
    '请填写正确的税号': 'Заполните правильный налоговый номер',
    '请填正确填写注册电话': 'Правильно заполните регистрационный номер телефона',
    '请填正确填写收票人电话':'Заполните правильный номер телефона получателя чека',
    '请填写发票抬头': 'Заполните заголовок счета',
    '请填写收票邮箱': 'Заполните почтовый  с квитанциями',
    '请填写单位名称': 'Введите название подразделения',
    '设置': 'настройки',
    '添加标题': 'Добавить заголовок',
    '请填写注册电话': 'Заполните регистрационный номер телефона',
    '请正确填写注册电话': 'Правильно заполните регистрационный номер телефона',
    '请填写开户银行': 'Заполните банк',
    '不可超出最大申请数量': 'Максимальное количество заявок не может быть превышено',
    '请填写收票人姓名': 'Введите имя получателя',
    '请填写收票人电话': 'Введите номер телефона получателя',
    '请正确填写收票人电话': 'правильно заполните номер телефона получателя',
    '请填写收票人地址': 'Заполнить адрес грузополучателя',
    '商品促销信息发生变化，请返回后重新下单': 'Изменение информации о продукте, повторный заказ',
    '商品促销信息发生变化': 'Информация о продвижении продукта изменилась',
    '提交订单失败，请稍后重试': 'Не удалось отправить заказ, попробуйте позже',
    ',2s后自动跳转订单列表': 'Автоматически переходить к списку заказов через 2 секунды',
    '请输入正确的': 'Введите правильный',
    '领取成功': 'Получено успешно',
    '取消该订单定金不予退还,确定取消?': 'Отменить заказ депозит не будет возвращен, вы уверены, чтобы отменить?',
    '请选择取消理由': 'Выберите причину отмены',
    '取消订单成功': 'Удаление заказа выполнено',
    '确认删除该订单?': 'Вы уверены, что хотите удалить этот заказ?',
    '删除订单成功': 'Удаление заказа выполнено',
    '确认收货?': 'Подтвердить получение товара?',
    '确认收货成功': 'Подтвердить получение успешно',
    '切换地址成功': 'Адрес успешно переключен',
    '下单': 'Оформить заказ',
    '请输入邮箱号': 'Введите номер почтового ящика',
    '评价成功': 'Оценка прошла успешно',
    '上传图片失败': 'Не удалось загрузить изображение ',
    '确定要清空吗?': ' Вы уверены, что хотите его очистить?',
    '从商品详情页进入': 'Вход со страницы сведений о товара',
    ' 点这里': ' кликните сюда',
    '请输入正确的账号/手机号': 'Введите правильный аккаунт/номер телефона',
    '上传图片成功': 'Загрузить изображение успешно',
    '图像应小于4MB': ' Изображение должно быть меньше 4 МБ',
    '从订单列表进入': 'Войти из списка заказов',
    '消息列表': 'Список сообщений',
    '请填写纳税人税号': 'Заполните налоговый номер налогоплательщика',
    '订单消息': 'Сообщение о заказе',
    '资产消息': 'Сообщение об активе',
    '售后消息': 'Сообщение о Послепродажи',
    '预约提醒': 'Напоминание о броне',
    '确定删除这些消息吗': 'Вы уверены, что хотите удалить эти сообщения',
    '请先选择要操作的消息！': 'Выберите сообщение! ',
    '请先绑定手机号，再进行操作!': 'Привязать номер мобильного телефона и продолжить!',
    '请输入旧密码': 'Введите старый пароль',
    '请输入一致的新密码': 'Введите новый пароль',
    '请输入一致的密码': 'Введите согласованный пароль',
    '请填写银行账户': 'Заполните банковский счет',
    '请输入物流单号！': 'Введите номер логистического заказа! ',
    '请输入正确的物流单号！': 'Введите правильный трекинг-номер',
    '请输入正确的支付密码': 'Введите правильный платежный пароль',
    '请选择支付方式': 'Способы оплаты',
    '支付成功,2s后自动跳转订单列表': 'Платеж прошел успешно, автоматически перейти к списку заказов через 2 секунды',
    '请输入旧支付密码': 'Введите свой старый платежный пароль',
    '请输入新支付密码': 'Введите новый платежный пароль',
    '请输入一致的新支付密码': 'Введите постоянный новый платежный пароль',
    '请输入一致的支付密码': 'Введите постоянный платежный пароль',
    '请输入充值金额': 'Введите сумму пополнения',
    '超过充值金额上限': 'Превышен верхний предел суммы пополнения',
    '充值成功,2s后自动跳转充值列表': 'Перезарядка прошла успешно, автоматически перейти к списку перезарядки через 2 секунды',
    '充值成功,2s后自动跳转支付页面': 'Пополнение счета прошло успешно, автоматический переход на страницу оплаты через 2 секунды',
    '请同意用户注册协议及隐私政策': 'Примите соглашение о регистрации пользователя и политику конфиденциальности',
    '从店铺详情页进入': 'Войти со страницы сведений о магазине',
    '请填写注册地址': 'Заполните регистрационный адрес',
    '请选择物流公司!': 'Выбор логистической компании!',
    '系统消息': 'Системная информация',
    '请输入5~40个字符': 'Ввод 5~40 символов',
    '切换语言': 'Переключить язык',
    '英语': 'Английский',
	'中文': 'Китайский',
    '俄文':'Русский язык',
	'交易中心':'торговый центр',
	'关注中心':'Центр заботы',
	'客户服务':'обслуживание клиентов',
	'会员中心':'Центр участников',
	'我的钱包':'кошельки',
	'店铺':'Магазин',
	'请输入正确的电话号码':'Пожалуйста, введите правильный номер телефона',
	'请输入 5~40 个字符':'Пожалуйста, введите 5~40 символов',
	'全部标为已读':'Отметить все как прочитанные',
	'批量标为已读':'Пакетная пометка как прочитанное',
	'无忧质保':'гарантия',
	'七天无理由退货':'Возврат 7 дней',
	'15 天免费换货':'15 дней бесплатного обмена',
	'满 99 包邮':'Бесплатная доставка на сумму свыше 99',
	'活动暂未开启':'Мероприятие еще не открыто',
	'成功加入购物车':'Успешно добавлено в корзину',
	'切换语言':'Переключение языка',
	
	'部分发货':'Частичная отгрузка',
	'客服':'первая помощь',
	'主营商品':'Основные продукты',
	
	'请输入正确的短信验证码':' Введите правильный код проверки SMS',
	'请输入正确的手机号':' Пожалуйста, введите правильный номер телефона. ',
	'密码最少6位哦':' Пароль минимум 6',
	'密码最多20位哦':' Пароль максимум 20',
	'密码不可以有中文哦':'Пароль не должен быть китайским',
	'密码中不可以有空格哦':'В пароле не должно быть пробелов',
	'请输入正确的邮箱验证码':'Введите правильный код почтового ящика',
	'请输入6～20位的会员名':'Введите имена 6 - 20 членов',
	'会员名不能全为数字':'Членство не может быть полностью цифровым',
	'会员名须由中、英文、数字、"-"及"_"组成':'Имя члена должно состоять из китайского, английского, цифры, "-" и ""',
	'请输入身份证号':'Введите идентификационный номер',
	'请输入正确的身份证号':'Введите правильный идентификационный номер',
	'请输入正确的图形验证码':'Введите правильный графический код',
	'请输入正确的邮箱':'Введите правильный почтовый ящик',
	'已加入购物车':'Присоединился к корзине покупок',
	'该功能在升级中～':'Эта функция в процессе обновления',
	'语言切换':'Переключение языка',
	'俄罗斯语':'Русский язык',
	
	'下单时间':'Время заказа',
	'订单号':'Номер заказа',
	'绑定手机：':'Связанные телефоны:',
	'安全性高的密码可以保障您的账号安全，建议密码为6～20位,由英文、数字或符号的组合':'Безопасный пароль может защитить вашу учетную запись, рекомендуя 6 - 20 - битный пароль, состоящий из комбинации английского языка, цифр или символов.',
    '手机验证':'Подтверждения из СМС',
    '邮箱验证':'Подтверждения по эл. почте',
    '退出登录':'Выход из системы',
    '账号密码':'Аккаунт Пароль',
    '请输入您的手机号码':'Введите номер вашего телефона',
    '哈萨克斯坦':'Казахстан',
    '俄罗斯':'Россия',
    '中国':'Китай',
    'KZ':'Казахстан',
    'RU':'Россия',
    'CN':'Китай',
    '通过手机':'на мобильный телефон',
    '通过邮箱':'по электронной почте',
    '请输入您的邮箱':'Введите электронный адрес',
    '邮箱地址':'Электронный адрес',
    '邮箱验证码':'Код из электронного письма',
    '请输入您的登录信息':'Введите информацию для входа в систему',
    '请确认您找回账户的邮箱/手机号':'Подтвердите электронный адрес/номер телефона, который использовался для восстановления аккаунта',
    '订单':'Заказ',
    '我们会向您输入的邮箱/手机号发送验证码':'Мы вышлем код подтверждения на этот адрес электронной почты/номер телефона',
    '验证码':'Код подтверждения',
    '新密码':'Новый пароль',
    '确认密码':'Подтвердить пароль',
    '两次密码输入不一致，请确认':'Введенные дважды пароли не совпадают, подтвердите',
    '密码':'Пароль',
    '手机号/账号':'Номер  телефона/Аккаунт',
    '商家入驻':'Регистрация продавца',
    'Clear all':'Удалить все',
    'History':'История',
    'Trending Now':'Рекомендации',
    '在dzon找你想买的任何东西':'Найдите в dzon все, что хотите',
    'See More Goods':'Посмотреть другие товары',
    '隐私及 cookie 策略':'"Политикой конфиденциальности и использования файлов cookie"',
    '手机号/邮箱':'Номер телефона/Электронный адрес',
    '验证':'Подтверждение',
    '没收到验证码？请检查垃圾箱，或重新获取':'Код подтверждения не получили? Проверьте папку Спам, или получите еще раз',
    '返回登录':'Вернуться и снова войти',
    '为了确保账号安全，请不要泄漏您的账户密码':'Для обеспечения безопасности вашего счёта, не раскрывайте аккаунт и пароль',
    '您的账号密码修改成功，请登录。':'Ваш пароль был успешно изменен, войдите.',
    '发票内容将显示商品名称与价格信息，发票金额为实际支付金额，不含优惠等扣减金额':'В содержании счета-фактуры будет указана наименование и цена товара, а сумма счета будет представлять собой фактическую сумму оплаты без учета скидок и других вычетов.',
    '发票内容将显示本单商品所属类别及价格信息，发票金额为实际支付金额，不含优惠等扣减金额。':'В содержании счета-фактуры будет указана категория, к которой относится товар данного заказа, и его цена, а сумма счета будет представлять собой фактическую сумму оплаты без учета скидок и других вычетов.',
    '个人':'Личность',
    '企业':'Предприятие',
    '发票信息':'Информация о счете-фактуре',
    '减':'минус',
    '元减':'юаней',
    '订单提交成功，请您尽快付款!':'Заказ успешно отправлен, оплатите как можно скорее!',
    '余额支付':'Оплатить баланс',
    '目前需要在线支付':'Сейчас требуется онлайн-оплата',
    '其他支付':'Другие способы оплаты',
    '点击继续，代表您同意我们的':'Если наживете Продолжить, то есть вы соглашаетесь с нашим',
    '用户协议':'Пользовательским соглашением',
    '以及':'и',
    '请输入正确手机号码':'Пожалуйста, введите правильный номер телефона. ',
    '请输入正确邮箱':'Введите правильный почтовый ящик ',
    '确认修改':'Подтверждение изменений',
    'All':'Каталог',
    'продано от dzon':'продано от dzon',
    '请输入正确密码':'Введите правильный пароль',
    '包裹':'Упаковка',
    '特惠':'Преференциальные',
    '所有评论已认证真实订单':'Все отзывы подтверждены как реальные заказы',
    '全部评价':'Все отзывы',
    '有图/有视频':'С картинками/видео',
    '低评价':'Низкие отзывы',
    '认证商家':'Сертифицированные коммерсанты',
    '关注人数':'Подписчик',
    '商品总数':'Общее количество товаров',
    '宝贝详情':'О товаре',
    '人已付款':'оплачено',
    '有效期至':'Срок действует до',
    '快递物流':'Логистика',
    '保护您放心购物':'Делать покупки с уверенностью',
    '购物安全':'Безопасность покупок',
    '产品参数':'характеристики',
    '查看更多规格':'Посмотреть другие спецификации',
    '为您推荐':'Рекомендуем',
    '记住卡号，这是安全的':'Запомнить карту. Это безопасно.',
    '保存卡号意味着你同意':'Сохраняя карту, вы соглашаетесь с ',
    '绑卡的条款和条件':'условиями привязки карты',
    '根据 PCI DSS 国际安全标准，银行卡数据受到安全保护和加密。':'Данные карты надёжно защищены и зашифрованы по международному стандарту безопасности PCI DSS.',
    '请勾选隐私协议':'Отметьте Соглашение о конфиденциальности',
    '请输入以下信息':'Введите следующие данные',
    '邮箱':'эл. почта',
    '国家':'страна',
    '请输入街道':'Введите улицу',
    '请输入门牌号':'Введите номер дома ',
    '请输入单元/办公室':'Введите квартиру/офис',
    '请输入邮编':'Введите почтовый индекс',
    '请填写街道信息':'Улица',
	'请填写门牌号':'Дом',
	'请填写单元/办公室':'Квартира/офис',
	'请填写邮编':'Индекс',
    '总价':'Общая стоимость',
    '总共上传':'Всего загрузить',
    '5张图片':'Пять фотографий '
};
